<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import DatePicker from "vue2-datepicker";
import Multiselect from "vue-multiselect";
import OrderReciept from "@/components/orderReciept";

/**
 * Advanced-table component
 */

export default {
  page: {
    title: "Day Part Report",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: { Layout, PageHeader, DatePicker, Multiselect, OrderReciept },
  data() {
    return {
      isTableBusy: false,

      tableData: [],
      cardData: [],

      title: "Day Part Report",
      items: [
        {
          text: "View",
        },
        {
          text: "Day Part Report",
          active: true,
        },
      ],
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, 100, 500, 1000, 2000, "All"],
      filter: null,
      filterOn: [],
      sortBy: "age",
      sortDesc: false,
      daterange: [],
      orderData: [],
      orderDetails: [],
      branches: [],
      branchID: "",
      restBranchID: this.$storageData.profile.restBranchID,
      cityArr: [],
      city: "",
      countryArr: [],
      country: "",
      brandArr: [],
      brandData: "",
      companyArr: [],
      companyID: 0,

      orderedItemArr: [],
      orderItemAddonArr: [],
      orderStatusArr: [],
      orderStatusOptions: [
        { statusID: "2", statusName: "Pending" },
        { statusID: "3", statusName: "Accepted" },
        { statusID: "4", statusName: "Preparing" },
        { statusID: "5", statusName: "Prepared" },
        { statusID: "6", statusName: "Dispatched" },
        { statusID: "7", statusName: "Delivered" },
        { statusID: "8", statusName: "Cancelled" },
      ],
      paymentType: "",
      paymentTypeArr: [
        "Free meal",
        "Wallet",
        "Subsidy + Wallet",
        "Pre-buy voucher",
        "Subsidy",
      ],
      orderTypeArr: [
        "Cafeteria",
        "Near by Restuarant",
        "Free Meal",
        "Team Meal",
      ],
      orderType: "",
      orderStatus: "",
      servingArr: [],
      timeFlag: 0,
      fields: [
        // {
        //     key: "Action",
        //     sortable: false,
        // },
        {
          key: "date",
          sortable: true,
        },
        {
          key: "orderID",
          sortable: true,
        },
        {
          key: "employeeName",
          lable: "Customer Name",
          sortable: true,
        },
        {
          key: "employeeID",
          lable: "Customer ID",
          sortable: true,
        },
        {
          key: "restaurant",
          sortable: true,
        },
        {
          key: "itemTotal",
          sortable: true,
          tdClass: "align-right",
        },
        {
          key: "couponDiscount",
          sortable: true,
          tdClass: "align-right",
        },
        {
          key: "GST",
          sortable: true,
          tdClass: "align-right",
        },
        {
          key: "otherCharges",
          sortable: true,
          tdClass: "align-right",
        },
        {
          key: "orderTotal",
          sortable: true,
          tdClass: "align-right",
        },
        {
          key: "paidAmount",
          sortable: true,
          tdClass: "align-right",
        },

        {
          key: "walletPoints",
          sortable: true,
          tdClass: "align-right",
        },
        {
          key: "voucherDiscount",
          sortable: true,
          tdClass: "align-right",
        },
        {
          key: "paidAmount",
          sortable: true,
          tdClass: "align-right",
        },
        {
          key: "corporateSubsidy",
          sortable: true,
          tdClass: "align-right",
        },
        {
          key: "GSTSubsidy",
          sortable: true,
          tdClass: "align-right",
        },

        {
          key: "PaymentModeName",
          sortable: true,
        },
        {
          key: "orderType",
          sortable: true,
        },
      ],
    };
  },
  computed: {
    /**
     * Total no. of records
     */
    rows() {
      return this.tableData.length;
    },
  },
  mounted() {
    // Set the initial number of items
    this.totalRows = this.items.length;

    //if(this.$storageData.profile.empTypeID==2 || this.$storageData.profile.empTypeID==11){
    this.getAllCountry();
    // }else{
    //   this.readOrdersData();
    // }
    if (this.$storageData.profile.empTypeID == 9) {
      this.getRestaurantBrands();
    } else {
      this.readOrdersData();
      this.getDishServingTime();
    }

    // added by Mohammed on 14-12-2021
    this.getRestaurantBrands();
  },
  methods: {
    /**
     * Search the table data with search input
     */
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },

    // changes done by Mohammed on 14-12-2021
    readOrdersData() {
      this.isTableBusy = true;
      this.axios
        .post(this.$loggedRole+"/dayPartReport", {
          restID: this.brandData.restID,
          restBranchID: this.branchID.restBranchID,
          daterange: this.daterange,
          paymentType: this.paymentType,
          orderType: this.orderType,
          timeFlag: this.timeFlag.id,
          empTypeID: this.$storageData.profile.empTypeID,
          empID: this.$storageData.profile.pid,
          city: this.$storageData.profile.city,
        })
        .then((response) => {
          this.isTableBusy = false;
          //Then injecting the result to datatable parameters.

          this.tableData = response.data.data;
          this.cardData = response.data.cardData;
          //console.log((response));
        });
    },
    dateFilter() {
      // alert(this.daterange);
      //this.readOrdersData();
    },
    // applyFilter(){
    //     this.readOrdersData();
    // },
    viewOrderDetails(orderID) {
      // alert(orderID);
      this.$root.$emit("bv::show::modal", "modal-lg-orderDetails");
      this.axios
        .post(this.$loggedRole+"/getOrderDetailsByID", { orderID: orderID })
        .then((result) => {
          this.orderDetails = result.data.data;
          this.orderStatusArr = result.data.data.orderStatus;
          //  console.log(result.data.data.orderedItemArr);
          this.orderedItemArr = result.data.data.orderedItemArr;
          // this.orderItemAddonArr = result.data.orderItemAddonArr;
        });
    },
    getRestaurantBrands() {
      this.axios
        .post(this.$loggedRole+"/getRestaurantList", {
          restID: this.$storageData.profile.restID,
          restBranchID: this.$storageData.profile.restBranchID,
          empTypeID: this.$storageData.profile.empTypeID,
          empID: this.$storageData.profile.pid,
          city: this.$storageData.profile.city,
        })
        .then((result) => {
          this.brandArr = result.data.data;
          this.readRestaurantBranchData();
        });
    },
    getDishServingTime() {
      this.servingArr = [];
      this.timeFlag = 0;
      this.axios.get(this.$loggedRole+"/getDishServingTime").then((response) => {
        this.servingArr = response.data.data;
        // this.country = (this.countryArr) ? this.countryArr[0] : "";
        // this.getAllCities();
      });
    },

    getAllCountry() {
      this.countryArr = [];
      this.country = "";
      this.axios.post(this.$loggedRole+"/getAllCountry").then((response) => {
        this.countryArr = response.data.data;
        // this.country = (this.countryArr) ? this.countryArr[0] : "";
        this.getAllCities();
      });
    },
    getAllCities() {
      this.cityArr = [];
      this.city = "";
      this.axios
        .post(this.$loggedRole+"/getAllCities", {
          country: this.country ? this.country.country : "",
        })
        .then((response) => {
          this.cityArr = response.data.data;
          // this.city = (this.cityArr) ? this.cityArr[0] : "";

          this.getRestaurantBrands();
        });
    },

    readRestaurantBranchData() {
      this.branches = [];
      this.branchID = "";
      this.axios
        .post(this.$loggedRole+"/getRestaurantBranchByRestID", {
          restID: this.brandData.restID,
          restBranchID: 0,
          empTypeID: this.$storageData.profile.empTypeID,
          empID: this.$storageData.profile.pid,
          city: this.city ? this.city.city : "",
        })
        .then((response) => {
          this.branches = response.data.data;
          //this.branchID = (this.branches) ? this.branches[0] :"";
          this.restBranchID = this.branchID ? this.branchID.restBranchID : "";
          //  this.readOrdersData();
        });
    },

    getAllCompanies() {
      this.companyArr = [];
      this.companyID = "";
      var companyID = 0;
      if (this.$storageData.profile.empTypeID == 11) {
        companyID = this.$storageData.profile.companyID;
      }
      this.axios
        .post(this.$loggedRole+"/getAllCompanies", { companyID: companyID })
        .then((response) => {
          this.companyArr = response.data.data;
          if (this.$storageData.profile.empTypeID == 11) {
            this.companyID = this.companyArr ? this.companyArr[0] : "";
          }
          //  this.getProductMixReport();
        });
    },

    getRestaurantCountry() {
      this.axios
        .post(this.$loggedRole+"/getRestaurantCountry", { restID: this.$storageData.profile.restID })
        .then((response) => {
          this.countryArr = response.data.data;
          this.country = this.countryArr ? this.countryArr[0] : "";
          this.getAllCompanies();
          this.getRestaurantCities();
        });
    },
    getRestaurantCities() {
      if (this.$storageData.profile.city != "") {
        this.cityArr = [{ city: this.$storageData.profile.city }];
        this.city = this.cityArr ? this.cityArr[0] : "";
        this.readRestaurantBranchData();
      } else {
        var restID = this.brandData.restID;
        if (this.$storageData.profile.empTypeID == 11) {
          restID = this.$storageData.profile.restID;
        }
        this.axios
          .post(this.$loggedRole+"/getRestaurantCities", {
            restID: restID,
            country: this.country ? this.country.country : "",
          })
          .then((response) => {
            this.cityArr = response.data.data;
            this.city = this.cityArr ? this.cityArr[0] : "";
            if (this.$storageData.profile.empTypeID == 11) {
              this.getRestaurantBrandsByCompanyID();
            } else {
              this.readRestaurantBranchData();
            }
          });
      }
    },

    getRestaurantBrandsByCompanyID() {
      var companyID = this.companyID;
      if (this.$storageData.profile.empTypeID == 11) {
        companyID = this.$storageData.profile.companyID;
      }
      this.axios
        .post(this.$loggedRole+"/getRestaurantBrandsByCompanyID", { companyID: companyID })
        .then((response) => {
          this.brandArr = response.data.data;
          // this.brandData = (this.brandArr) ? this.brandArr[0] : "";
          this.readRestaurantBranchData();
        });
    },

    onchangeBranch() {
      this.restBranchID = this.branchID.restBranchID;
      // this.readOrdersData();
    },
    applyFilter() {
      if (this.daterange.includes(null)) {
        this.daterange = [];
      }
      this.readOrdersData();
    },
    clearFilter() {
      this.country = "";
      this.companyID = "";
      this.brandData = "";
      this.city = "";
      this.branchID = "";
      this.daterange = [];
      this.paymentType = "";
      this.orderType = "";
      this.readOrdersData();
    },
  },
  middleware: "authentication",
};
</script>

<template>
  <Layout>
    <!-- <PageHeader :title="title" :items="items" /> -->
    <PageHeader :items="items" />
    <div class="card" style="margin-top:-30px">
      <div class="card-body">
        <div class="inner mb-2 row">
          <!-- Country List-->
          <div
            class="col-md-2"
            style="width:12%"
            v-if="
              this.$storageData.profile.empTypeID == 1 ||
                this.$storageData.profile.empTypeID == 2 ||
                this.$storageData.profile.empTypeID == 11
            "
          >
            <label>Country </label>
            <multiselect
              v-model="country"
              :options="countryArr"
              track-by="country"
              label="country"
              @input="getAllCompanies()"
              :show-labels="false"
            ></multiselect>
          </div>
          <!-- Country List END-->
          <!-- Company List-->
          <div
            class="col-md-2"
            style="width:16%"
            v-if="
              this.$storageData.profile.empTypeID == 1 ||
                this.$storageData.profile.empTypeID == 11
            "
          >
            <label>Company</label>
            <multiselect
              v-model="companyID"
              :options="companyArr"
              track-by="companyID"
              label="companyName"
              @input="getRestaurantBrandsByCompanyID()"
              :show-labels="false"
            ></multiselect>
          </div>
          <!-- Compnay List END-->
          <!-- Brand List-->
          <div
            class="col-md-2"
            style="width:16%"
            v-if="
              this.$storageData.profile.empTypeID == 1 ||
                this.$storageData.profile.empTypeID == 9 ||
                this.$storageData.profile.empTypeID == 11
            "
          >
            <label>Restaurant Brand</label>
            <multiselect
              v-model="brandData"
              :options="brandArr"
              track-by="restID"
              label="name"
              @input="getRestaurantCities()"
              :show-labels="false"
            ></multiselect>
          </div>
          <!-- Brand List END-->
          <!-- Cities List-->
          <div
            class="col-md-2"
            style="width:12%"
            v-if="
              this.$storageData.profile.empTypeID == 1 ||
                this.$storageData.profile.empTypeID == 2 ||
                this.$storageData.profile.empTypeID == 9 ||
                this.$storageData.profile.empTypeID == 11
            "
          >
            <label>City </label>
            <multiselect
              v-model="city"
              :options="cityArr"
              track-by="city"
              label="city"
              @input="readRestaurantBranchData()"
              :show-labels="false"
            ></multiselect>
          </div>
          <!--END Cities List-->
          <!-- Branch List-->
          <div
            class="col-md-2"
            style="width:16%"
            v-if="
              this.$storageData.profile.empTypeID == 1 ||
                this.$storageData.profile.empTypeID == 2 ||
                this.$storageData.profile.empTypeID == 9 ||
                this.$storageData.profile.empTypeID == 11
            "
          >
            <label>Restaurant Branch </label>
            <multiselect
              v-model="branchID"
              :options="branches"
              :show-labels="false"
              track-by="restBranchID"
              label="branchName"
              @input="onchangeBranch()"
            ></multiselect>
          </div>
          <!-- Branch List END-->
          <!-- Time slot List-->
          <div
            class="col-md-2"
            style="width:20%"
            v-if="
              this.$storageData.profile.empTypeID == 1 ||
                this.$storageData.profile.empTypeID == 2 ||
                this.$storageData.profile.empTypeID == 9 ||
                this.$storageData.profile.empTypeID == 11
            "
          >
            <label>Time Slot </label>
            <multiselect
              v-model="timeFlag"
              :options="servingArr"
              :show-labels="false"
              track-by="id"
              label="serveTimeName"
              @input="onchangeBranch()"
            ></multiselect>
          </div>
          <!-- Time slott END-->
          <!-- Date range -->
          <div class="col-md-2" style="width:20%;margin-top:10px;">
            <label>Date Range </label>
            <date-picker
              v-model="daterange"
              range
              append-to-body
              lang="en"
              confirm
              value-type="format"
              @change="onchangeBranch()"
            ></date-picker>
          </div>
          <!-- Date range END-->
          <!-- Payment Type -->
          <div class="col-md-2" style="width:18%;margin-top:10px;">
            <label>Payment Type </label>
            <multiselect
              v-model="paymentType"
              :options="paymentTypeArr"
              @input="onchangeBranch()"
            ></multiselect>
          </div>
          <!-- Payment Type END-->
          <!-- Order Type -->
          <div class="col-md-2" style="width:18%;margin-top:10px;">
            <label>Order Type </label>
            <multiselect
              v-model="orderType"
              :options="orderTypeArr"
              @input="onchangeBranch()"
            ></multiselect>
          </div>
          <!-- Order Type END-->

          <!-- Apply filter -->
          <div class="col-md-2" style="width:auto;margin-top:10px;">
            <button class="btn btn-themeOrange mt-4" v-on:click="applyFilter()">
              Apply Filter
            </button>
          </div>
          <!-- Apply filter END-->

          <!-- Clear filter -->
          <div class="col-md-2" style="width:16%;margin-top:10px;">
            <button class="btn btn-themeBrown mt-4" v-on:click="clearFilter()">
              Clear Filter
            </button>
          </div>
          <!-- Clear filter END-->
        </div>
      </div>
    </div>
    <!--FILTER END------>

    <div class="row">
      <!-- <div class="col-md-2" style="position: absolute;right: 48%;top: 75px;"
        >   

                     <label>Select Country </label>
                        <multiselect v-model="country" :options="countryArr" track-by="country" label="country"
                          @input="getAllCities();" :show-labels="false"></multiselect>
         </div> -->
      <!-- Cities List-->
      <!-- <div class="col-md-2"  style="position: absolute;right: 31%;top: 75px;"
       >   

                     <label>Select City </label>
                        <multiselect v-model="city" :options="cityArr" track-by="city" label="city"
                          @input="readRestaurantBranchData();" :show-labels="false"></multiselect>
         </div> -->

      <!-- <div class="form-group mb-3 col-md-3" style="position: absolute;
    right: 12%;
    top: 75px;
    width: 19%;">
                            <label>Date Range</label>
                            <br />
                            <date-picker v-model="daterange" range append-to-body lang="en" confirm @change="onchangeBranch();"></date-picker>
                </div> -->
      <!-- <div class="col-md-1" style="position: absolute; right: 4%;
    top: 75px;">
                 <button class="btn btn-themeBrown mt-4"  v-on:click="clearFilter()"> Clear Filter </button>
            </div> -->

      <!--END Cities List-->
      <!-- <div class="row mt-3">
                    <div class="col-md-3 mb-3" >   

                     <label>Select Restaurant Brand</label>
                        <multiselect v-model="brandData" :options="brandArr" track-by="restID" label="name"
                          @input="readRestaurantBranchData();" :show-labels="false"></multiselect>
                    </div>

                  <div class="col-md-3" >
                    <label>Select Restaurant Branch </label>
                        <multiselect v-model="branchID" :options="branches" :show-labels="false" track-by="restBranchID" label="branchName"
                          @input="onchangeBranch();"></multiselect>
                </div>
                
                <div class="col-md-3">
                     <label>Payment Type</label>
                        <multiselect v-model="paymentType" :options="paymentTypeArr" @input="onchangeBranch();"></multiselect>
                </div>
                 <div class="col-md-3">
                     <label>Order Type</label>
                        <multiselect v-model="orderType" :options="orderTypeArr" @input="onchangeBranch();"></multiselect>
                </div>
               
              
        </div> -->

      <!--  -->
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <b-tabs
              justified
              nav-class="nav-tabs-custom"
              content-class="p-3 text-muted"
            >
              <b-tab active>
                <template v-slot:title>
                  <span class="d-inline-block d-sm-none">
                    <i class="fas fa-home"></i>
                  </span>
                  <span class="d-none d-sm-inline-block">Overview</span>
                </template>

                <div class="row" style="margin-top: 30px;">
                  <div class="col-md-6 col-xl-3">
                    <div class="card">
                      <div class="card-body">
                        <div>
                          <h4 class="mb-1 mt-1">
                            <!-- <h4 class="mb-1 mt-1"> -->
                            {{ cardData.totalOrders }}
                            <!-- <span data-plugin="counterup">
                            <countTo :startVal="1000" :endVal="voucherData.totalAmountRedeemed" :duration="2000"></countTo>
                        </span> -->
                          </h4>
                          <p class="text-muted mb-0">Total Orders</p>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="col-md-6 col-xl-3">
                    <div class="card">
                      <div class="card-body">
                        <div>
                          <h4 class="mb-1 mt-1">
                            ₹ {{ cardData.totalOrderAmount }}
                            <!-- <span data-plugin="counterup">
                            <countTo :startVal="1000" :endVal="voucherData.redeemedVouchers" :duration="2000"></countTo>
                        </span> -->
                          </h4>
                          <p class="text-muted mb-0">Total Order Amount</p>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="col-md-6 col-xl-3">
                    <div class="card">
                      <div class="card-body">
                        <div>
                          <h4 class="mb-1 mt-1">
                            ₹ {{ cardData.totalGSTCollected }}
                            <!-- <span data-plugin="counterup">
                            <countTo :startVal="1000" :endVal="voucherData.redeemedVouchers" :duration="2000"></countTo>
                        </span> -->
                          </h4>
                          <p class="text-muted mb-0">Total GST Collected</p>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="col-md-6 col-xl-3">
                    <div class="card">
                      <div class="card-body">
                        <div>
                          <h4 class="mb-1 mt-1">
                            ₹ {{ cardData.totalAmountCollected }}
                          </h4>
                          <p class="text-muted mb-0">Total Amount Collected</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <!-- <div class="col-md-2"  style="width:16%;margin-bottom:10px;" v-if="this.$storageData.profile.empTypeID==1 || this.$storageData.profile.empTypeID==2 || this.$storageData.profile.empTypeID==11">
                            <label>Sort By: </label>
                                <multiselect v-model="sortFlag" :options="sortingGrapArr" :show-labels="false" track-by="id" label="name"
                          @input="onchangeBranch();"></multiselect>
                  </div> -->

                <!-- <div class="row"> -->
                <!-- <div class="col-md-6">
      <div class="card">
      <div class="card-body">

          <h6> Top Buying Customer </h6> -->
                <!-- Bar chart -->
                <!-- <apexchart type="line" class="apex-charts" dir="ltr" height="339" :options="salesGraph.topBuyingCustomers.chartOptions" :series="salesGraph.topBuyingCustomers.series"></apexchart> -->
                <!-- <apexchart
                  class="apex-charts"
                  height="350"
                  type="bar"
                  dir="ltr"
                  :series="salesGraph.topBuyingCustomers.series"
                  :options="salesGraph.topBuyingCustomers.chartOptions"
              ></apexchart>
      </div>
      </div>
    </div> -->

                <!-- <div class="col-md-6">
      <div class="card">
      <div class="card-body">

          <h6> Top Selling Restaurant </h6> -->
                <!-- Bar chart -->
                <!-- <apexchart type="line" class="apex-charts" dir="ltr" height="339" :options="salesGraph.topSellingRestaurants.chartOptions" :series="salesGraph.topSellingRestaurants.series"></apexchart> -->
                <!-- <apexchart
                  class="apex-charts"
                  height="350"
                  type="bar"
                  dir="ltr"
                  :series="salesGraph.topSellingRestaunrants.series"
                  :options="salesGraph.topSellingRestaunrants.chartOptions"
              ></apexchart>
      </div>
      </div>
    </div> -->
                <!-- </div> -->

                <!-- <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <h4 class="card-title mb-4">Sales Graph</h4> -->
                <!-- Bar Chart -->
                <!-- <apexchart type="line" class="apex-charts" dir="ltr" height="339" :options="salesGraph.chartOptions" :series="salesGraph.series"></apexchart> -->
                <!-- </div>
        </div>
      </div> -->
              </b-tab>
              <b-tab>
                <template v-slot:title>
                  <span class="d-inline-block d-sm-none">
                    <i class="far fa-user"></i>
                  </span>
                  <span class="d-none d-sm-inline-block">List</span>
                </template>
                <div class="col-12">
                  <div class="card">
                    <div class="card-body">
                      <div class="row mt-4">
                        <div
                          class="col-sm-12 col-md-4"
                          style="margin-bottom:15px;"
                        >
                          <div role="group" class="btn-group">
                            <button type="button" class="btn btn-themeBrown" v-if="this.$storageData.login_type == 1">
                              Excel
                            </button>
                            <!-- <button type="button" class="btn btn-themeBrown">
                              Column Visibility
                            </button> -->
                          </div>
                        </div>
                        <div class="col-sm-12 col-md-4">
                          <div
                            id="tickets-table_length"
                            class="dataTables_length"
                          >
                            <label class="d-inline-flex align-items-center">
                              Show &nbsp;&nbsp;
                              <b-form-select
                                style="margin-left:5px; margin-right:5px"
                                v-model="perPage"
                                size="sm"
                                :options="pageOptions"
                              ></b-form-select
                              >&nbsp;&nbsp;&nbsp; entries
                            </label>
                          </div>
                        </div>
                        <!-- Search -->
                        <div class="col-sm-12 col-md-4 row">
                          <div
                            id="tickets-table_filter"
                            class="dataTables_filter text-md-end"
                          >
                            <label class="d-inline-flex align-items-center">
                              Search:
                              <b-form-input
                                v-model="filter"
                                type="search"
                                placeholder="Search..."
                                class="form-control form-control-sm ms-2"
                              ></b-form-input>
                            </label>
                          </div>
                        </div>
                        <!-- End search -->
                      </div>
                      <!-- Table -->
                      <div class="table-responsive mb-0">
                        <b-table
                          striped
                          hover
                          outlined
                          bordered
                          :items="tableData"
                          :fields="fields"
                          responsive
                          :per-page="perPage"
                          :current-page="currentPage"
                          :sort-by.sync="sortBy"
                          :sort-desc.sync="sortDesc"
                          :filter="filter"
                          :filter-included-fields="filterOn"
                          @filtered="onFiltered"
                          :busy="isTableBusy"
                        >
                          <template #table-busy>
                            <div class="text-center text-danger my-2">
                              <b-spinner class="align-middle"></b-spinner>
                              <strong>Loading...</strong>
                            </div>
                          </template>

                          <!-- <template #cell(Action)>
                                <i class="uil uil-edit-alt" title="Edit" style="font-size: 19px;"></i>
                                <i class="mdi mdi-delete" title="Delete" style="font-size: 19px;"></i>
                          </template> -->

                          <template v-slot:cell(orderID)="data">
                            <span
                              style="cursor:pointer;color:#2069f1;"
                              @click="viewOrderDetails(data.item.id)"
                              >{{ data.item.orderID }}</span
                            >
                          </template>

                          <template v-slot:cell(employeeName)="data">
                            <span> {{ data.item.userName }}</span>
                          </template>
                          <template v-slot:cell(restaurant)="data">
                            <span> {{ data.item.restBranchName }}</span>
                          </template>
                          <template v-slot:cell(couponDiscount)="data">
                            <span>₹ {{ data.item.Discount }}</span>
                          </template>
                          <template v-slot:cell(itemTotal)="data">
                            <span>₹ {{ data.item.itemTotal }}</span>
                          </template>

                          <template v-slot:cell(GST)="data">
                            <span v-if="data.item.tax"
                              >₹ {{ data.item.tax }}</span
                            >
                            <span v-else> 0 </span>
                          </template>
                          <template v-slot:cell(otherCharges)="data">
                            <span v-if="data.item.restCharges"
                              >₹ {{ data.item.restCharges }}</span
                            >
                            <span v-else> 0 </span>
                          </template>
                          <template v-slot:cell(orderTotal)="data">
                            <span>₹ {{ data.item.orderTotal }}</span>
                          </template>
                          <template v-slot:cell(walletPoints)="data">
                            <span v-if="data.item.walletDiscount">
                              {{ data.item.walletDiscount }}</span
                            >
                            <span v-else> 0 </span>
                          </template>
                          <template v-slot:cell(voucherDiscount)="data">
                            <span v-if="data.item.voucherDiscount">
                              ₹ {{ data.item.voucherDiscount }}</span
                            >
                            <span v-else> 0 </span>
                          </template>

                          <template v-slot:cell(paidAmount)="data">
                            <span>₹ {{ data.item.paidAmount }}</span>
                          </template>
                          <template v-slot:cell(corporateSubsidy)="data">
                            <span v-if="data.item.corporateSubsidy">
                              ₹ {{ data.item.corporateSubsidy }}</span
                            >
                            <span v-else> 0 </span>
                          </template>
                          <template v-slot:cell(GSTSubsidy)="data">
                            <span v-if="data.item.GSTSubsidy">
                              ₹ {{ data.item.GSTSubsidy }}</span
                            >
                            <span v-else> 0 </span>
                          </template>
                        </b-table>
                      </div>
                      <div class="row">
                        <div class="col">
                          <div
                            class="dataTables_paginate paging_simple_numbers float-end"
                          >
                            <ul class="pagination pagination-rounded mb-0">
                              <!-- pagination -->
                              <b-pagination
                                v-model="currentPage"
                                :total-rows="rows"
                                :per-page="perPage"
                              ></b-pagination>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </b-tab>
            </b-tabs>
          </div>
        </div>
      </div>

      <!--  -->
    </div>
    <!-- modals -->
    <OrderReciept
      :orderDetails="this.orderDetails"
      :orderedItemArr="this.orderedItemArr"
      :orderStatusArr="this.orderStatusArr"
    >
    </OrderReciept>
  </Layout>
</template>

<style scoped>
.my-class {
  max-width: 10px !important;
}
</style>
